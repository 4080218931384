import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import { AppBar, Toolbar, ButtonBase, Button, Typography } from '@material-ui/core';
// import SettingsIcon from '@material-ui/icons/Settings';

import logo from 'assets/logo.png';
import mainStyles from 'styles/mainStyles';



const Header = props => {
	const classes = mainStyles();
	const { uuid, lang } = useParams();
	const history = useHistory();

	let lan = (lang === 'da' || lang === 'en') ? lang : 'da';

	const handleLogoClick = () => {
	}

	const handleLangClick = () => {
		if (lan === 'da') {
			history.push('/feedback/' + uuid + '/en');
		} else {
			history.push('/feedback/' + uuid + '/da');
		}
	}

	return (
		<AppBar position="static" elevation={0} className={classes.header}>
			<Toolbar>
				<div className={classes.logoContainer}>
					<ButtonBase
						disableRipple={true}
						onClick={handleLogoClick}
					>
						<img src={`${logo}`} alt="Climaid logo" className={classes.logo} />
					</ButtonBase>
				</div>
				<div className={classes.spacer}></div>
				{props.useLang ? <Button onClick={handleLangClick} className={classes.langButton}>DK/EN</Button> : ""}
			</Toolbar>
			{props.roomName ? <Typography variant="h2" className={classes.roomName}>{props.roomName}</Typography> : ""}
		</AppBar>
	)
}

export default Header;